import { isDefined } from "@clipboard-health/util-ts";
import { Stack } from "@mui/material";
import { type Facility } from "@src/appV2/Facilities/types";
import { max as maxDate, parseISO } from "date-fns";

import { AsOfDateText } from "../../components/AsOfDateText";
import { InformationSectionTextContent } from "../../components/InformationSectionTextContent";
import { List, ListItem, ListItemText } from "../../components/List";
import { WorkplaceNoteIdentifier } from "../constants";
import { type WorkplaceNotesRecord } from "../useWorkplaceNotesParsed";

interface BreaksSectionProps {
  requiresLunchBreak: Facility["requiresLunchBreak"];
  requiresLunchBreakSince: Facility["requiresLunchBreakSince"];
  providesRestBreaks: Facility["providesRestBreaks"];
  providesRestBreaksSince: Facility["providesRestBreaksSince"];
  workplaceNotes: WorkplaceNotesRecord;
}

export function BreaksSection(props: BreaksSectionProps) {
  const {
    requiresLunchBreak,
    requiresLunchBreakSince,
    providesRestBreaks,
    providesRestBreaksSince,
    workplaceNotes,
  } = props;

  const mandatoryBreakNote = workplaceNotes[WorkplaceNoteIdentifier.MANDATORY_BREAK_POLICY];
  const restBreakNote = workplaceNotes[WorkplaceNoteIdentifier.REST_BREAK_POLICY];

  const breakPolicyDates: Date[] = [
    requiresLunchBreakSince,
    providesRestBreaksSince,
    mandatoryBreakNote?.updatedAt,
    restBreakNote?.updatedAt,
  ]
    .filter((value): value is string => isDefined(value))
    .map((value) => parseISO(value));

  const asOfDate = breakPolicyDates.length > 0 ? maxDate(breakPolicyDates) : new Date();

  return (
    <List dense>
      <AsOfDateText date={asOfDate} />

      {requiresLunchBreak && mandatoryBreakNote && (
        <ListItem>
          <ListItemText
            primary="Unpaid Meal Breaks"
            secondary={
              <Stack spacing={2}>
                <InformationSectionTextContent>
                  This workplace requires a 30-minute unpaid break and enforces the following
                  policy:
                </InformationSectionTextContent>
                <InformationSectionTextContent>
                  {mandatoryBreakNote?.note}
                </InformationSectionTextContent>
                <InformationSectionTextContent>
                  If you work through the break, you will have to submit additional documentation.
                  The payment for the additional worked time may be withheld for up to 3 business
                  days while the break payment request undergoes a separate approval process.
                </InformationSectionTextContent>
              </Stack>
            }
          />
        </ListItem>
      )}

      {providesRestBreaks && restBreakNote && (
        <ListItem>
          <ListItemText primary="Paid Rest Periods" secondary={restBreakNote?.note} />
        </ListItem>
      )}
    </List>
  );
}
